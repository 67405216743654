* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  background-color: #fffbfb;
  color: #333;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #22589a;
}

h2 {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #371413;
}

button {
  font-family: inherit;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #a94442;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #843534;
}

button:active {
  background-color: #69282b;
}

input[type='file'] {
  display: none;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Nav {
  padding-bottom: 1rem;
}

.Nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.Nav li {
  padding: 0 1rem;
}

.Nav li a {
  color: #22589a;
  text-decoration: none;
}

.Nav li a:hover {
  color: #0056b3;
}

.Nav li a:active {
  color: #122222;
}