.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  width: -webkit-fill-available;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: .5rem auto;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  margin: .1rem;
}

.tag button {
  background-color: transparent;
  border: none;
  margin-left: 0.25rem;
  color: #333;
  font-weight: 700;
  padding: 0;
}

.tags-input input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
}

.IngredientLens {
  background-color:#ededed;
  border: 2px dashed #555555;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  width: auto;
  max-width: 800px;
  margin-bottom: .5rem;
  align-self: center;
  text-align: -webkit-center;
}

.IngredientLens button {
  margin-top: 0.5rem;
}

.IngredientLens p {
  font-size: .7rem;
  color: #333;
  font-style: italic;
}

.IngredientInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.IngredientInput input {
  margin: .5rem;
  width: -webkit-fill-available;
}

.IngredientInput button:hover {
  background-color: transparent;
}

.IngredientInput p {
  font-size: .7rem;
  color: #333;
  padding: .5rem .5rem 0 .5rem;
  margin: 0 2rem;
}

.or {
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: .5rem;
  display: block;
}

.IngredientList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  font-size: 1rem;
  font-weight: 300;
  color: #333;
}

.IngredientList ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.IngredientList button {
  background-color: transparent;
  color: #a94442;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0;
  margin-left: 0.5rem;
  border: none;
  cursor: pointer;
}

.IngredientList button:hover {
  color: #fff;
  background-color: #a94442;
}

.IngredientList button:active {
  color: #69282b;
}

.BakeButton {
  margin-bottom: 1rem;
}

.RecipeDisplay {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
}

.RecipeDisplay h2 {
  margin: 10px 0;
  text-align: left;
}

.RecipeDisplay pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .tags-input {
    margin: 0 1rem;
  }
}

@media (max-width: 360px) {
  .tags-input {
    margin: 0 .25rem;
  }
}

.spinner {
  border: .2rem solid rgba(0, 0, 0, 0.2);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-disabled,
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.camera-icon {
  display: flex;
  flex-direction:column;
}

.form-container table {
  padding-top: 1rem;
}

.form-container label {
padding-top: 1rem;
}

.form-container label#isExecutiveChef {
  padding-top: 1rem;
  margin-left: .3rem;
  }

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: left;
}

.row-container button {
 margin-top: 1rem; 
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-input,
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-family: inherit;
}

/* MultiStepForm container */

.outter-container {
  width: auto;
}

.form-container,
.form-container-member {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 600px;
  margin: .5rem auto;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  width: -webkit-fill-available;
  border: 1px solid #ccc;
}

.form-container-member button#edit {
    cursor: pointer;
    padding: 0.25rem .5rem;
    border: none;
    border-radius: .2rem;
    font-size: .6rem;
    color: #007bff;
    background-color: transparent;
    transition: background-color 0.2s;
    vertical-align: middle;
}

.form-container ul {
  list-style: none; 
}

.form-container li {
  text-align: left; 
}

.form-container td {
  font-size: .6rem; 
}

.form-container th {
 font-size: .6rem;
 padding: .2rem;
}

/* Spacing between form steps */
.step-container {
  margin-bottom: 1.5rem;
}

/* Form labels and inputs */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="number"],
select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

label#isExecutiveChef,
input#isExecutiveChef {
  margin-top: 1rem;
}

/* Buttons */
button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Space between buttons */
button + button {
  margin-left: 1rem;
}

/* Household Members Summary */
.household-members-summary {
  margin-top: 1.5rem;
  text-align: center;
}

.household-members-summary ul {
  list-style-type: none;
  padding: 0;
}

.household-members-summary li {
  margin-bottom: 0.5rem;
}

/* Other global styles */
h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Meal toggle buttons */
.meal-toggle {
  display: inline-block;
  width: 4rem;
  height: 1.5rem;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.meal-toggle.meal-active {
  background-color: #cce5ff;
}

.meal-toggle:focus {
  outline: none;
}

.prompt-container pre {
  white-space: pre-wrap;
}

.delete {
  background-color: transparent;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 5px;
}

.delete {
  cursor: pointer;
  border: none;
  border-radius: .2rem;
  font-size: .6rem;
  color: rgba(255, 0, 0);
  background-color: transparent;
  transition: background-color 0.2s;
  margin-right: .4rem;
  padding: .1rem .25rem;
}

.delete:hover {
  background-color: rgba(255, 0, 0, 0.4);
  color: white;
}

.delete:active {
  background-color: rgba(255, 0, 0);
  color: white;
}

.household-member-container {
  text-align: left;
}

.household-member-container p {
  display: inline-block;
  vertical-align: middle;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  white-space: nowrap;
}

.executive-chef-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: default;
  vertical-align: middle;
}

.form-container-member table {
  border-collapse: collapse;
  width: 100%;
}

.form-container-member th,
.form-container-member td {
  padding: .2rem;
  text-align: center;
}

.form-container-member th {
  padding-top: .3rem;
  padding-bottom: .3rem;
  font-size: .5rem;
  color: black;
  text-align: center;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  padding: 0 5px;
}

.icon-button:hover {
  color: #007bff;
}

.meal-icon {
  padding: .1rem;
}

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 600px;
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
  width: -webkit-fill-available;
  align-items: center;
}

.meal-plan-container {
  text-align: left;
}

.meal-plan-container pre {
  white-space: pre-wrap;
  padding-top: 1rem;
}

.summary-container button {
  margin: 0 0 1rem 0;
}

.summary-container hr {
  width: -webkit-fill-available;
  height: 1px;
  margin-bottom: 1rem;
}